import logo from './assets/logo.png';
import './App.css';

function App() {
  return (
    <div className="container">
      <img src={logo} className="logo" alt="Custodio Logo" />
      <h1>
        Hi! 👋
      </h1>
      <p>
        I'm Custodio.
      </p>
      <p>
        Please, don't hurt me.
      </p>
    </div>
  );
}

export default App;
